import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import SubMasterEditForm from 'manage-tritag/components/pages/seasons/edit-form'

const SeasonEditPage = (props: PageProps) => {
  return (
    <Layout label1="Seasons" label2="Update" url="/seasons">
      <SubMasterEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(SeasonEditPage)
